import React from "react";
import { useController } from "react-hook-form";

const FormInput = ({ control, name, type, placeholder, rules, ...rest }) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, error }
  } = useController({
    name,
    control,
    rules,
    defaultValue: ""
  });

  return (
    <div style={{ width: "100%" }}>
      <input type={type || "text"} placeholder={placeholder} ref={ref} {...inputProps} {...rest} />
      {invalid && <span style={{ color: "red" }}>{error?.message}</span>}
    </div>
  );
};

export default FormInput;
