import React, { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import Api from "../../apiClients/api";
import AddProductModal from "../../modals/AddProductModal";
import { useAuth } from "../../contexts/Auth";

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [edit, setEdit] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const { logout } = useAuth();

  const getData = () =>
    Api.get(`/db/`)
      .then((result) => result.data)
      .then(setData);

  const onSuccess = () => {
    setLoading(true);
    getData().then(() => setLoading(false));
  };

  const deleteAttribute = (id) => Api.delete(`/db/${id}`).then(onSuccess);

  useEffect(() => {
    getData().then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (edit) {
      setShowAddProduct(true);
    }
  }, [edit]);

  useEffect(() => {
    if (edit && !showAddProduct) {
      setEdit(null);
    }
  }, [showAddProduct]);

  const handleEdit = (item) => setEdit(item);

  const handleDelete = (item) => {
    if (confirm(`Are you sure you want to delete ${item.name}`) == true) {
      deleteAttribute(item.id);
    } else {
      alert(`Delete cancelled for ${item.name}`);
    }
  };

  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return loading ? (
    <Spinner />
  ) : (
    <>
      <button
        style={{ display: "block", marginLeft: "auto", marginRight: "1rem" }}
        onClick={logout}
      >
        Logout
      </button>
      {showAddProduct && (
        <AddProductModal
          open={showAddProduct}
          onClose={() => setShowAddProduct(false)}
          obj={edit}
          onSuccess={onSuccess}
        />
      )}
      <h1 style={{ textAlign: "center" }}>Product Attributes</h1>

      <input
        style={{ width: "50%", display: "block", margin: "0 auto 2rem auto" }}
        type="text"
        placeholder="Search by attribute name or attribute type"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      {filteredData.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            minHeight: "inherit"
          }}
        >
          <div>
            <h3>
              {searchQuery !== "Attribute not found"
                ? ""
                : "You have no custom attributes for the product's model"}
            </h3>
            <button
              style={{ margin: "auto", display: "block" }}
              onClick={() => setShowAddProduct(true)}
            >
              Add Attribute
            </button>
          </div>
        </div>
      ) : (
        <>
          <div style={{ marginBottom: "40px" }}>
            <button
              style={{ margin: "auto", display: "block" }}
              onClick={() => setShowAddProduct(true)}
            >
              Add Another Attribute
            </button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "inherit"
            }}
          >
            <table border="1" style={{ width: "50%" }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th style={{ width: "25%" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item) => (
                  <tr key={item.id}>
                    <td style={{ textAlign: "center" }}>{item.name}</td>
                    <td style={{ textAlign: "center" }}>{item.type}</td>
                    <td
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        padding: "0 20px"
                      }}
                    >
                      <button
                        onClick={() => handleEdit(item)}
                        style={{ minWidth: "10%", margin: "10px 0" }}
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(item)}
                        style={{ minWidth: "10%", margin: "10px 0" }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
