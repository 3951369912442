import React, { useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FormProvider, useForm } from "react-hook-form";
import SelectField from "../../components/SelectField";
import FormInput from "../../components/FormInput";
import Api from "../../apiClients/api";

function FormGroup({ children }) {
  return <div style={{ marginTop: "20px" }}>{children}</div>;
}

function CharFieldForm() {
  return (
    <div style={{ paddingBottom: "2rem" }}>
      <FormGroup>
        <label>Choices</label>
        <SelectField name="choices" isCreatable isMulti />
      </FormGroup>
    </div>
  );
}

function BooleanForm() {
  return (
    <div style={{ paddingBottom: "2rem" }}>
      <FormGroup>
        <label>Should the Default value be True?</label>
        <FormInput name="default" type="checkbox" />
      </FormGroup>
    </div>
  );
}

function DateTimeFieldForm() {
  return (
    <div style={{ paddingBottom: "2rem" }}>
      <FormGroup>
        <label>Should the Default value be be the current date or current date and time?</label>
        <FormInput name="should_datetime_default_now" type="checkbox" />
      </FormGroup>
    </div>
  );
}

export default function AddProductModal({ open, onClose, obj, onSuccess }) {
  const methods = useForm();
  const OPTIONS = [
    "CharField",
    "TextField",
    "DateField",
    "DateTimeField",
    "URLField",
    "BooleanField",
    "IntegerField",
    "FloatField"
  ];

  const forms = {
    CharField: <CharFieldForm />,
    BooleanField: <BooleanForm />,
    DateTimeField: <DateTimeFieldForm />,
    DateField: <DateTimeFieldForm />
  };
  const type = methods.watch("type");

  const createAttribute = (data) => Api.post("/db/", data);

  const updateAttribute = (data) => Api.put(`/db/${obj.id}/`, { ...data, id: obj.id });

  const onSubmit = (data) =>
    (obj ? updateAttribute(data) : createAttribute(data))
      .then(onSuccess)
      .then(() => window.alert("Saved successfully"))
      .then(() => onClose());

  useEffect(() => {
    if (obj && open === true) {
      var keys = Object.keys(obj);
      for (var i = 0; i < keys.length; i++) {
        methods.setValue(keys[i], obj[keys[i]]);
      }
    }
  }, [open, obj]);

  const resetForm = () => {
    const fields = methods.getValues();
    var keys = Object.keys(fields);
    for (var i = 0; i < keys.length; i++) {
      if (!["type", "name"].includes(keys[i])) {
        methods.resetField(keys[i]);
      }
    }
  };

  useEffect(() => {
    resetForm();
  }, [type]);

  return (
    <Modal open={open} onClose={onClose} center styles={{ modal: { width: "50vw" } }}>
      <FormProvider {...methods}>
        <h1>Add Attribute</h1>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormGroup>
            <label>Attribute Title</label>
            <FormInput
              name="name"
              required
              pattern="[a-zA-Z_][a-zA-Z0-9_]*"
              title="The attribute name should not contain spaces and must start with a letter (uppercase or lowercase) or underscore, followed by letters, numbers, or underscores."
            />
          </FormGroup>
          <FormGroup>
            <label>Attribute Type</label>
            <SelectField name="type" options={OPTIONS} />
          </FormGroup>
          {type && type !== "" && forms[type]}
          <button type="submit">SUBMIT</button>
        </form>
      </FormProvider>
    </Modal>
  );
}
