import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import CreatableSelect from "react-select/creatable";

const SelectField = ({ name, options = [], isCreatable, isMulti }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) =>
        isCreatable ? (
          <CreatableSelect {...field} isClearable options={options} isMulti={isMulti} />
        ) : (
          <select
            {...field}
            style={{
              padding: "10px",
              fontSize: "16px",
              width: "100%",
              borderRadius: "5px",
              border: "1px solid #ccc"
            }}
          >
            <option value="" disabled>
              Select a field type
            </option>
            {options.map((el) => (
              <option value={el} key={el}>
                {el}
              </option>
            ))}
          </select>
        )
      }
    />
  );
};

export default SelectField;
