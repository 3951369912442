import axios from "axios";
import API_BASE_URL from "../config/api";

axios.defaults.baseURL = API_BASE_URL;

export class ApiHeaders {
  get defaultHeaders() {
    return {};
  }
}

class Api extends ApiHeaders {
  static async get(path, params = {}, headers = {}) {
    return await axios.get(path, { params, headers });
  }

  static async post(path, data = {}, headers = {}) {
    return await axios.post(path, data, { headers });
  }

  static async put(path, data = {}, headers = {}) {
    return await axios.put(path, data, { headers });
  }

  static async patch(path, data = {}, headers = {}) {
    return await axios.patch(path, data, { headers });
  }

  static async delete(path, params = {}, headers = {}) {
    return await axios.delete(path, { params, headers });
  }
}

export default Api;
