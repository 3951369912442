import React, { useState } from "react";
import "./styles.css";
import Error from "../../components/Error";
import { useAuth } from "../../contexts/Auth";
import Api from "../../apiClients/api";

const LoginScreen = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login } = useAuth();

  const handleLogin = () => {
    if (!username || !password) {
      setError("Please enter a username and a password");
    } else {
      setError("");
      Api.post(`/db/login/`, { username, password })
        .then(login)
        .catch((error) => {
          setError(error.response.data.error);
        })
        .catch((error) => {
          console.log(error);
          setError("Invalid Response from Server");
        });
    }
  };

  return (
    <div className="container">
      <h2>Database Management Login</h2>
      <Error>{error}</Error>

      <form className="form">
        <label className="label">Username</label>
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        <label className="label">Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input"
        />
        <button type="button" onClick={handleLogin} className="button">
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginScreen;
