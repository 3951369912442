import React, { createContext, useContext, useState } from "react";
import Cookies from "universal-cookie";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const cookies = new Cookies();
  const [isLoggedIn, setLoggedIn] = useState(!!cookies.get("loggedIn"));

  const login = () => {
    cookies.set("loggedIn", true, { path: "/", secure: true });
    setLoggedIn(true);
  };

  const logout = () => {
    cookies.remove("loggedIn", { path: "/" });
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ login, logout, isLoggedIn }}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
