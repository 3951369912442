import React from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import { useAuth } from "./contexts/Auth";

function AuthenticatedRoute({ children }) {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? children : <Navigate to="/login" />;
}

const AppRoutes = () => {
  const { isLoggedIn } = useAuth();
  return (
    <Router>
      <Routes>
        {!isLoggedIn && <Route path="/login" element={<Login />} />}
        <Route
          path="/dashboard/"
          element={
            <AuthenticatedRoute>
              <Dashboard />
            </AuthenticatedRoute>
          }
        />
        <Route path="/*" element={<Navigate to={isLoggedIn ? "/dashboard" : "/login"} replace />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
